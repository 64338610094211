import { render, staticRenderFns } from "./Root.vue?vue&type=template&id=2b0d4271"
import script from "./Root.vue?vue&type=script&lang=js"
export * from "./Root.vue?vue&type=script&lang=js"
import style0 from "./Root.vue?vue&type=style&index=0&id=2b0d4271&prod&lang=sass"
import style1 from "./Root.vue?vue&type=style&index=1&id=2b0d4271&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports